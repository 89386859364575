import React, {
  useEffect,
  useState
} from 'react';
import Navbar from '../Home/Navbar';
import Footer from './Footer';
import './Home.css';
import { Icons } from '../Common/Icons/Icons';
import icon1 from '../../assets/images/WeddingsIcon.svg';
import icon2 from '../../assets/images/BarIcon.svg';
import icon3 from '../../assets/images/PhotographerIcon.svg';
import icon4 from '../../assets/images/BirthdayIcon.svg';
import icon5 from '../../assets/images/SnorkelIcon.svg';
import icon6 from '../../assets/images/AndMoreIcon.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slide1 from '../../assets/images/slider/slide1.webp';
import slide2 from '../../assets/images/slider/slide2.webp';
import slide3 from '../../assets/images/slider/slide3.webp';
import varian1 from '../../components/Images/Variant4.webp';
import varian2 from '../../components/Images/Variant5.webp';
import varian3 from '../../components/Images/Variant6.webp';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import section1 from '../Images/image1.webp';
import section1small from '../Images/image1small.webp';
import section2 from '../../assets/images/HomeSection2.webp';
import section3 from '../../assets/images/HomeSection3.webp';

import popupImageEs from '../../assets/images/popupes.jpg';
import popupImageEn from '../../assets/images/popup.jpg';
// Con tus otros imports al inicio del archivo



const Home = () => {
  const navigate = useNavigate();
  const images = [varian1, varian2, varian3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { t: translate, i18n } = useTranslation(); // añade i18n aquí
  const t = (value: string) => translate(`t:components.home.${value}`);
  const [showPopup, setShowPopup] = useState(true);

  
 
 // Función para obtener la imagen según el idioma
 const getPopupImage = () => {
  return i18n.language === 'es' ? popupImageEs : popupImageEn;
};
  
   // Modifica el useEffect para manejar el popup
   useEffect(() => {
    const popupKey = `hasShownPopup_${i18n.language}`; // Clave única por idioma
    const hasShownPopup = localStorage.getItem(popupKey);
    
    if (!hasShownPopup) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [i18n.language]); // Se ejecuta cuando cambia el idioma

  const handleClosePopup = () => {
    const popupKey = `hasShownPopup_${i18n.language}`;
    localStorage.setItem(popupKey, 'true');
    setShowPopup(false);
  };
  
  const containerStyle = {
    height: '100vh',
    margin: 0,
    padding: 0,
  };

   
// Estilos del popup (añade esto junto a tus otros estilos)
const popupStyles = {
  overlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: showPopup ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99999,
  },
  content: {
    position: 'relative' as const,
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
  closeButton: {
    position: 'absolute' as const,
    top: '-15px',
    right: '-15px',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'white',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  image: {
    maxWidth: '100%',
    maxHeight: 'calc(90vh - 40px)',
    objectFit: 'contain' as const,
  }
};
  const divStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  };

  const customIconStyle: React.CSSProperties = {
    position: 'absolute',
    top: -50,
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    zIndex: 1,
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the currentImageIndex to the next image
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [currentImageIndex, images.length]);


  return (

    
    <div style={containerStyle}>

  

      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      }}>
        <Navbar />
      </div>
      {/* ----------------------------------- section1 ----------------------------------- */}
      <div style={divStyle}>
        <img
          src={window.innerWidth <= 767 ? section1small : section1}
          alt='Background image'
          className='home-section1-image'
          width='100%'
          height='100%'
        />
        <div className='home-section1'>
          <div className='home-title-section'>
            <div className='home-title'>{t('title1')}</div>
            <div className='home-title'>{t('title2')}</div>
            <button onClick={() => navigate('/stepper')} className='home-section1-button home-section1-button-text' aria-label='Here'>
              {t('sectionBttn')}
            </button>
          </div>
          <div className='smallImg'>
            <img src={images[currentImageIndex]} alt='Carousel image' width="100%" height="100%"/>
          </div>
        </div>

      </div>


      {/* ----------------------------------- section2 ----------------------------------- */}
      <div className='home-section2-container'>
        <img src={section2} alt='Background image' className='home-section2-image' width='100%' height='100%'/>
        <Icons.LogoFilled width={151} height={119} style={customIconStyle} />
        <div className='home-section2-content'>

          {t('section2Txt')}<br />
        Casa de campo - La Romana
        </div>
      </div>


      {/* ----------------------------------- section3 ----------------------------------- */}
      <div className='home-section3-1'>
        {t('section3Txt1')}<br />
        {t('section3Txt2')}
      </div>

      <div className='home-section3-2'>
        <img src={section3} alt='Background image' className='home-section3-image' width='100%' height='100%'/>
        <button onClick={() => navigate('/vessels')} className='home-section3-button home-section3-button-text' aria-label='Here'>
          {t('sectionBttn')}
        </button>
      </div>

      {/* ----------------------------------- section4 ----------------------------------- */}
      <div className='home-section4'>
        <div className='home-section4-title-section home-section4-title'>
          <div className='home-section4-seperator-hidden' />
          {t('section4Txt')}
          <div className='home-section4-seperator' />
        </div>
        <div className='home-section4-icons'>
          <div className='home-section4-icon-section'>
            <a href={'/experiences'}>
              <img src={icon1} className='home-section4-icon-element' alt='Background image' width={116} height={116} />
            </a>
            {t('weddings')}
          </div>
          <div className='home-section4-icon-section'>
            <a href={'/experiences'}>
              <img src={icon2} className='home-section4-icon-element' alt='Background image' />
            </a>
            {t('bar')}
          </div>
          <div className='home-section4-icon-section'>
            <a href={'/experiences'}>
              <img src={icon3} className='home-section4-icon-element' alt='Background image' />
            </a>
            {t('photographer')}
          </div>
          <div className='home-section4-icon-section'>
            <a href={'/experiences'}>
              <img src={icon4} className='home-section4-icon-element' alt='Background image' />
            </a>
            {t('birthday')}
          </div>
          <div className='home-section4-icon-section'>
            <a href={'/experiences'}>
              <img src={icon5} className='home-section4-icon-element' alt='Background image' />
            </a>
            {t('snorkel')}
          </div>
          <div className='home-section4-icon-section'>
            <a href={'/experiences'}>
              <img src={icon6} className='home-section4-icon-element' alt='Background image' />
            </a>
            {t('more')}
          </div>
        </div>
      </div>


      {/* ----------------------------------- section5 ----------------------------------- */}
      <div className='home-section5'>
        <Slider {...settings}>
          <div>
            <img className='home-section5-image' src={slide1} alt="Slide 1" width='100%' height='100%'/>
          </div>
          <div>
            <img className='home-section5-image' src={slide2} alt="Slide 2" width='100%' height='100%'/>
          </div>
          <div>
            <img className='home-section5-image' src={slide3} alt="Slide 3" width='100%' height='100%'/>
          </div>
        </Slider>
      </div>



      <div className='home-footer'>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
