import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import Home from './components/Home/Home';
import AboutUs from './components/AboutUs/AboutUs';
import ContactUs from './components/ContactUs/ContactUs';
import Experiences from './components/Experiences/Experiences';
import Faq from './components/Faq/Faq';
import Vessels from './components/Vessels/Vessels';
import MoreInfo from './components/Vessels/MoreInfo';
import ExperiencesDetails from './components/Experiences/ExperiencesDetails';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import ScrollTop from './components/ScrollTop/ScrollTop';
import whatsapp from '././assets/images/WhatsappFixLogo.webp';
import Stepper from './components/Stepper/Stepper';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const App = () => {
  return (

    <I18nextProvider i18n={i18n}>
      <div>
        <Router>
          <ScrollTop/>
          <Routes>
            <Route
              path={'/'}
              element={<Home/>}
            />
            <Route
              path={'/experiences'}
              element={<Experiences />}
            />
            <Route
              path={'/experiences-details'}
              element={<ExperiencesDetails/>}
            />
            <Route
              path={'/about'}
              element={<AboutUs />}
            />
            <Route
              path={'/stepper'}
              element={<Stepper />}
            />
            <Route
              path={'/contact'}
              element={<ContactUs />}
            />
            <Route
              path={'/faq'}
              element={<Faq />}
            />
            <Route
              path={'/vessels'}
              element={<Vessels />}
            />
            <Route
              path={'/more-info'}
              element={<MoreInfo />}
            />
            <Route
              path={'/privacy-policy'}
              element={<PrivacyPolicy />}
            />
            <Route
              path={'/terms-and-conditions'}
              element={<TermsAndConditions />}
            />
          </Routes>
        </Router>
        <a href={`whatsapp://send?phone=+18097574780`} target="_blank" rel="noreferrer" className={'whatsappLink'} aria-label='Whatsapp button'>
          <img src={whatsapp} className={'whatsappFixLogo'} alt='Whatsapp icon'/>
        </a>

      </div>
    </I18nextProvider>
  );
};

export default App;
